import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StructuredTextDocument } from 'react-datocms';
import { motion, useDragControls } from 'framer-motion';
import Container from '../../../primitives/grid/container';
import Typography, { smallTextStyles } from '../../../primitives/typography';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
// eslint-disable-next-line import/no-cycle
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import { getLink, LinkWithTitle } from '../../../primitives/link';
import DatoAsset from '../../../primitives/asset';
import StyledLink from '../../../primitives/styled-link';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import RowSet from '../../../primitives/grid/row-set';
import AspectRatio from '../../../primitives/aspect-ratio';
import { colors } from '../../../primitives/colors';

export type TeamGridProps = Queries.TeamGridPropsFragment;

interface TeamGridCardProps {
    image?: Queries.DatoCmsInternalAssetFragment | null
    name?: string | null
    title?: string | null
    blurb?: string | null
    socialLinks?: LinkWithTitle
}

const TeamGridCard = ({
    image,
    name,
    title,
    blurb,
    socialLinks,
}: TeamGridCardProps): JSX.Element => (
    <div css={{
        borderRadius: borderRadius.large,
        position: 'relative',
        userSelect: 'none',
        transition: '0.3s',
        border: `1px solid ${colors.grey100}`,
        boxShadow: '0 24px 54px 0 rgba(0, 0, 0, 0.0)',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100%',
    }}
    >
        {image && (
            <AspectRatio
                breakpoints={{
                    dt: { width: 294, height: 196 },
                }}
                css={{
                    overflow: 'hidden',
                    borderTopLeftRadius: borderRadius.large,
                    borderTopRightRadius: borderRadius.large,
                    transform: 'translateZ(0px)',
                }}
            >
                <DatoAsset css={{ height: '100%', width: '100%' }} asset={image} />
            </AspectRatio>
        )}
        <div css={{
            paddingTop: '28px',
            paddingLeft: '30px',
            paddingRight: '30px',
            paddingBottom: '23px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
        }}
        >
            <RowSet
                breakpoints={{
                    dt: { between: 16 },
                }}
                css={{
                    margin: '0',
                }}
            >
                <div>
                    <Typography
                        fontSize={{
                            dt: 2027,
                        }}
                        color="primaryOrange"
                        fontWeight="light"
                    >
                        {name}
                    </Typography>
                    <Typography css={{
                        ...smallTextStyles,
                        opacity: 0.7,
                        marginTop: '4px',
                    }}
                    >
                        {title}
                    </Typography>
                </div>
                <Typography
                    fontSize={{
                        dt: 1322,
                    }}
                    fontWeight="light"
                >
                    {blurb}
                </Typography>
            </RowSet>
            {(socialLinks && socialLinks.length > 0) && (
                <RowSet
                    css={{
                        margin: 0,
                        marginTop: '16px',
                    }}
                    breakpoints={{
                        dt: { between: 4 },
                    }}
                >
                    {socialLinks && socialLinks.map((socialLink) => {
                        if (!socialLink) return null;
                        return (
                            <div>
                                <StyledLink
                                    to={getLink(socialLink?.link)}
                                >
                                    {socialLink?.title}
                                </StyledLink>
                            </div>
                        );
                    })}
                </RowSet>
            )}
        </div>
    </div>
);

const TeamGrid = ({ richTitle }: TeamGridProps): JSX.Element => {
    const {
        allDatoCmsTeam: {
            nodes: teamMembers,
        },
    } = useStaticQuery<Queries.TeamMembersGridQuery>(graphql`
        query TeamMembersGrid {
            allDatoCmsTeam {
                nodes {
                    name
                    title
                    blurb
                    position
                    socialLinks {
                        ...LinkWithTitleProps
                    }
                    image {
                        ...DatoCmsInternalAsset
                    }
                }
            }
        }
    `);

    const controls = useDragControls();
    const [isDragging, setIsDragging] = React.useState(false);

    const startDrag = (event: React.MouseEvent): void => {
        setIsDragging(true);
        controls.start(event);
    };

    const endDrag = (): void => {
        setIsDragging(false);
    };

    const ref = React.useRef<HTMLDivElement>(null);

    return (
        <div css={{
            marginTop: '96px',
            marginBottom: '120px',
            overflow: 'hidden',
            [breakpoints.mb]: {
                marginTop: '55px',
                marginBottom: '55px',
            },
        }}
        >
            <Container>
                <Row css={{
                    marginBottom: '62px',
                    [breakpoints.mb]: {
                        marginBottom: '42px',
                    },
                }}
                >
                    <Col breakpoints={{ dt: { span: 12 } }}>
                        <Typography
                            fontSize={{
                                dt: 4453,
                                tb: 3234,
                                mb: 2429,
                            }}
                            css={{
                                textAlign: 'center',
                            }}
                            override
                            fontWeight="light"
                        >
                            <CustomStructuredTextRenderer data={richTitle as unknown as StructuredTextDocument} />
                        </Typography>
                    </Col>
                </Row>
                <Row css={{
                    rowGap: '24px',
                    [breakpoints.mb]: {
                        display: 'none',
                    },
                }}
                >
                    {teamMembers.sort((a, b) => a.position - b.position).map((teamMember) => {
                        if (!teamMember) return null;

                        return (
                            <Col breakpoints={{ dt: { span: 3 }, tb: { span: 6 } }}>
                                <TeamGridCard
                                    name={teamMember.name}
                                    title={teamMember.title}
                                    image={teamMember.image}
                                    blurb={teamMember.blurb}
                                    socialLinks={teamMember.socialLinks}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </Container>
            <div
                ref={ref}
                css={{
                    position: 'relative',
                    display: 'none',
                    [breakpoints.mb]: {
                        display: 'unset',
                    },
                }}
            >
                <div
                    css={{
                        position: 'relative',
                        width: '100%',
                        minHeight: '417px',
                        pointerEvents: 'all',
                        [breakpoints.mb]: {
                            minHeight: 'unset',
                        },
                    }}
                    onPointerDown={startDrag}
                    onPointerUp={endDrag}
                    style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                >
                    <motion.div
                        css={{
                            [breakpoints.mb]: {
                                display: 'flex',
                                width: '100%',
                                paddingLeft: 'max(5vw, calc(50% - calc(calc(1440px / 2) + 5vw) + 5vw))',
                                minWidth: `calc(${294 * teamMembers.length + 24 * (teamMembers.length - 1)}px + 10vw)`,
                            },
                        }}
                        dragControls={controls}
                        dragConstraints={ref}
                        drag="x"
                    >
                        {teamMembers.sort((a, b) => a.position - b.position).map((teamMember, idx) => (
                            <div css={{
                                [breakpoints.mb]: {
                                    width: '100%',
                                    flex: 'none',
                                    display: 'flex',
                                    maxWidth: '294px',
                                    marginRight: idx !== teamMembers.length - 1 ? '24px' : '0',
                                },
                            }}
                            >
                                <TeamGridCard
                                    name={teamMember.name}
                                    title={teamMember.title}
                                    image={teamMember.image}
                                    blurb={teamMember.blurb}
                                    socialLinks={teamMember.socialLinks}
                                />
                            </div>
                        ))}
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default TeamGrid;

export const query = graphql`
    fragment TeamGridProps on DatoCmsTeamGrid {
        richTitle {
            blocks
            links
            value
        }
    }
`;
